@import "variables";

#adtm_menu {position: relative; padding: 0 !important; clear:both;text-align:left;zoom:1;}
#adtm_menu_inner {border-style:solid; padding: 0 !important;}
#adtm_menu ul#menu { margin:0; padding: 0 !important; list-style:none;}
#adtm_menu ul#menu li.li-niveau1,
#adtm_menu ul#menu li.li-niveau1 a.a-niveau1{
	 line-height: normal !important; height: auto !important;
}
#adtm_menu ul#menu li.li-niveau1:not(:last-child) {border-bottom: #4f4f4f solid 1px;}
#adtm_menu ul#menu li.li-niveau1 a.a-niveau1 { display:block; border-style:solid; text-decoration:none; }
#adtm_menu ul#menu li.li-niveau1 a.a-niveau1 .advtm_menu_span {padding:15px;display:block;font-family: filson-pro, sans-serif;
    font-weight: 700; line-height: normal !important; height: auto !important;}
/* IE6 */ * html #adtm_menu ul#menu li.li-niveau1 a.a-niveau1 .advtm_menu_span {display:inline-block;}
#adtm_menu ul#menu li.sub a.a-niveau1 {}
#adtm_menu ul#menu li.li-niveau1 a.a-niveau1:hover, #adtm_menu ul#menu li.li-niveau1:hover a.a-niveau1 {}
#adtm_menu ul#menu li.sub a.a-niveau1:hover {}
#adtm_menu ul#menu li.sub:hover > a.a-niveau1 span {}
#adtm_menu ul#menu li div.adtm_sub { position:absolute; left:-9999px; padding:0;border-style:solid;}
/* IE6 */ * html #adtm_menu table { border-collapse:collapse; margin:-1px -10px; font-size:1em; width:0; height:0; }
#adtm_menu ul#menu :hover div.adtm_sub {left:0;}
#adtm_menu ul#menu a:hover div.adtm_sub {left:-1px; top:29px;}
#adtm_menu div.adtm_column_wrap {}
#adtm_menu div.adtm_column_wrap span.column_wrap_title {padding:5px 0 0 0; margin:0;text-align:left; font-weight: bold; display: block}
#adtm_menu div.adtm_column_wrap ul {padding:0; margin:0; list-style:none;zoom:1;}
#adtm_menu div.adtm_column_wrap ul li {line-height:15px; padding:0; margin:0;zoom:1;}
#adtm_menu div.adtm_column_wrap ul li a {display:block; text-decoration:none; font-weight:normal; text-align:left;}
#adtm_menu div.adtm_column_wrap ul li a:hover {text-decoration:underline;}
#adtm_menu table.columnWrapTable {margin:0;padding:0; border-collapse:collapse;}
#adtm_menu table.columnWrapTable tr td {vertical-align:top;}
#adtm_menu table.columnWrapTable tr td div.adtm_column_wrap {}
#adtm_menu .adtm_column_wrap_sizer {font-size:0;height:0;line-height:0;}
/* Fix bug IE link with filter property */
#adtm_menu ul#menu li.li-niveau1 a.a-niveau1 {cursor : pointer;}
/* Not available on PS 1.5 */
.clear { clear: both }
img.adtm_menu_icon {vertical-align:middle;margin-right:3px;}
#adtm_menu .searchboxATM .search_query_atm {width:150px;margin-left:0!important;}
#adtm_menu .searchboxATM .button_mini {display:inline-block;}
#adtm_menu .adtm_unclickable {text-decoration:none!important;}


#adtm_menu_inner{
	width: 100% !important;
}

.adtm_sub{
	top: 0 !important;
	display: none;
}

.li-niveau1{
	position: static !important;
}
.li-niveau1.sub:hover .adtm_sub{
	display: block;
	left: 100% !important;
	width: 60vw;
	/*-moz-box-shadow: 11px 11px 10px 0px rgba(0,0,0,0.1);
	-webkit-box-shadow: 11px 11px 10px 0px rgba(0,0,0,0.1);
	-o-box-shadow: 11px 11px 10px 0px rgba(0,0,0,0.1);
	box-shadow: 11px 11px 10px 0px rgba(0,0,0,0.1);*/
	min-height: 100%;
	overflow-y: auto;
}
@media(min-width:991px){
	.columnWrapTable{
		padding: 30px;
	}
}

@media(max-width:990px){
	.columnWrapTable{
		padding: 5px;
	}
	.li-niveau1.sub:hover .adtm_sub{
		width: 40vw;
	}
	.li-niveau1.sub:hover .adtm_sub{
		height: 100vh;
		overflow-y: scroll;
	}
}

@media(min-width:991px){
	.columnWrapTable{
		display: table;
		width: 100%;
	}
	.adtm_column_wrap_td{
		display: table-cell;
		float: none;
		padding-left: 30px;
		padding-right: 30px;
		vertical-align: top;
		/*border-right:#e4e4e4 solid 1px;*/
	}
}

/*.adtm_column_wrap_td:last-child{
	border-left:none;
	padding-left: 15px;
}*/
.adtm_column_wrap_td:nth-child(3),
.adtm_column_wrap_td:nth-child(4){
	border-right:none;
}
.adtm_column_wrap_td:last-child{
	border:none;
}

[class*=" advtm_menu_"] > a::before{
	font-family: 'diamwoodFont' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	color: theme-color(white);
	margin: 9px 7px 0 15px;
	float: left;
	font-size: 24px
}
[class*=" advtm_menu_"] > a.advtm_menu_actif::before,
[class*=" advtm_menu_"]:hover > a::before{
	color: theme-color(black);
}
.advtm_menu_1 > a::before {
  content: "\e904";
}
.advtm_menu_2 a{
	margin-left: -4px;
}
.advtm_menu_2 > a::before {
	content: "\e903";
	font-size: 28px;
}
.advtm_menu_3 > a::before {
  content: "\e907";
}
.advtm_menu_4 > a::before {
  content: "\e908";
}
.advtm_menu_5 > a::before {
  content: "\e906";
}
.advtm_menu_6 > a::before {
  content: "\e902";
}
.advtm_menu_7 > a::before {
  content: "\e90a";
}
.advtm_menu_8 > a::before {
  content: "\e905";
}
.advtm_menu_9 > a::before {
  content: "\e901";
}
.advtm_menu_10 > a::before {
  content: "\e909";
}
.advtm_menu_11 > a::before {
  content: "\e900";
}
